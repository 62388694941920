import React, { useState } from 'react'
import styles from './form.module.css'
import ReactInputMask from 'react-input-mask';

const Form = () => {
  // Estados para armazenar os valores dos inputs
  const [formData, setFormData] = useState({
    client_name: '',  // Corrigido para 'client_name'
    phonenumber: '',  // Corrigido para 'phonenumber'
    email: '',
    businessarea: ''  // Corrigido para 'businessarea'
  });

  // Função para lidar com as mudanças nos inputs
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  // Função para enviar o formulário
  const handleSubmit = async (e) => {
    e.preventDefault(); // Evita o comportamento padrão de envio de formulário

    //const url = 'https://send.tgdsolucoes.net/api/landingpage/'
    const url = 'http://localhost/api/landingpage/'
    console.log('url:', url, 'data', formData)
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      });

      if (response.ok) {
        const data = await response.json();
        console.log('Formulário enviado com sucesso:', data);
        alert('Formulário enviado com sucesso!');
        setFormData({
          client_name: '',
          phonenumber: '',
          email: '',
          businessarea: ''
        });
      } else {
        console.error('Erro ao enviar formulário');
        alert('Erro ao enviar o formulário.');
      }
    } catch (error) {
      console.error('Erro na requisição:', error);
    }
  };

  return (
    <form onSubmit={handleSubmit} className={styles.form}>
      <div className={styles.labels}>
        <input
          className='input'
          type="text"
          id="client_name"
          name="client_name" // Corrigido para coincidir com o estado
          value={formData.client_name}
          onChange={handleChange}
          placeholder='Informe seu nome'
          required
        />
      </div>

      <div className={styles.labels}>
        <ReactInputMask
          className='input'
          mask="99999999999" // Aplica a máscara de 11 dígitos
          id="phonenumber"
          name="phonenumber"
          value={formData.phonenumber}
          onChange={handleChange}
          placeholder='Informe seu whatsapp com DDD'
          required
        />
      </div>

      <div className={styles.labels}>
        <input
          className='input'
          type="email"
          id="email"
          name="email"
          placeholder='Informe seu e-mail'
          value={formData.email}
          onChange={handleChange}
          required
        />
      </div>

      <div className={styles.labels}>
        <input
          className='input'
          type="text"
          id="businessarea"
          name="businessarea" // Corrigido para coincidir com o estado
          placeholder='Informe qual área do seu negócio'
          value={formData.businessarea}
          onChange={handleChange}
          required
        />
      </div>

      <button type="submit" className='button'>Enviar</button>
    </form>
  );
};

export default Form;
